import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { CustomerGroupsComponent } from '../customer-groups/customer-groups.component';
import { CustomerComponent } from '../customer/customer.component';


@Injectable({
    providedIn: 'root'
  })
export class StartupService {

    private _startupData: UserModel;

    private get _router() { return this._injector.get(Router); }

    constructor(private http: HttpClient, private _injector: Injector) { }

    // initialiseAppUsingPromises() {
    //     let obsv$ = this.initialiseAppUsingObservables();
    //     return lastValueFrom(obsv$); //alternative to toPromise()
    //   }

    init() {
        //return new Promise<void>(async (resolve, reject) => {

            const routes = this._router.config;

            // routes.push({ path: '', component: AdminComponent});
            // routes.push({ path: 'customer-groups', component: CustomerGroupsComponent});

            return this.http.get('/api/users/me').subscribe((data: UserModel) => {
                this._startupData = data;

                if (data.role === 'Admin') {
                    routes.push({ path: '', component: AdminComponent});
                    routes.push({ path: 'customer-groups', component: CustomerGroupsComponent});
                } else {
                    routes.push({ path: '', component: CustomerComponent});
                }

                this._router.resetConfig(routes);
                //resolve();
            });

            //resolve();
        //});
    }

    get startupData(): any {
        return this._startupData;
    }
}

export class UserModel {
    name: string;
    role: string;
}
function lastValueFrom(obsv$: any) {
    throw new Error('Function not implemented.');
}

