import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  catchError,
  of,
  throwError,
  BehaviorSubject,
  map,
  switchMap,
  tap,
  lastValueFrom,
} from 'rxjs';
import { UserModel } from './startup.service';
import { Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { CustomerGroupsComponent } from '../customer-groups/customer-groups.component';
import { CustomerComponent } from '../customer/customer.component';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient, private _injector: Injector) {}

  public _startupData: UserModel;

  private get _router() { return this._injector.get(Router); }

  public alertSubject = new BehaviorSubject<any>(null);
  public alertObservable = this.alertSubject.asObservable();

  getUser() {
    return this.http
      .get('/api/users/me')
      //.get(`https://jsonplaceholder.typicode.com/users/${userId}`)
      .pipe(catchError((err) => throwError(err)));
  }

  // getToDos(userId: number) {
  //   //return throwError('error getting todos'); //uncomment this to test how errors are handled when getToDos() fails
  //   return this.http
  //     .get(`https://jsonplaceholder.typicode.com/todos?userId=${userId}`)
  //     .pipe(catchError((err) => throwError(err)));
  // }

  setUser(data) {
    const routes = this._router.config;
    this._startupData = data;

    if (data.role === 'Admin') {
        routes.push({ path: '', component: AdminComponent});
        routes.push({ path: 'customer-groups', component: CustomerGroupsComponent});
    } else {
        routes.push({ path: '', component: CustomerComponent});
    }
    this._router.resetConfig(routes);
  }

  initialiseAppUsingObservables() {
    return this.getUser().pipe(
      map((x) => {
        this.setUser(x);
      },
      // switchMap((x: any) => {

      //   this.setUser(x);
      //   //return this.getToDos(x.id).pipe(tap((y) => this.setToDos(y)));
      // }),
      catchError((err) => {
        this.alertSubject.next(err);
        return of(err);
      })
    ));
  }

  initialiseAppUsingPromises() {
    let obsv$ = this.initialiseAppUsingObservables();
    return lastValueFrom(obsv$);
  }
}