import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorInterceptor } from './error.interceptor';
import { StartupService } from './services/startup.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { AdminComponent } from './admin/admin.component';
import { CustomerComponent } from './customer/customer.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { daLocale } from 'ngx-bootstrap/locale';
import { PricePipe } from './pipes/price.pipe';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { CustomerGroupsComponent } from './customer-groups/customer-groups.component';
import { DataService } from './services/data.service';
defineLocale('da', daLocale);

function appInitializerUsingPromises(
    dataService: DataService
  ): () => Promise<any> {
    return () =>
      new Promise((resolve, reject) => {
        dataService
          .initialiseAppUsingPromises()
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            resolve(true);
          });
      });
  }

// export function startupServiceFactory(startupService: StartupService): Function {
//     return () => startupService.init();
// //   return (): Promise<any>  => {
// //     return ;
// //   };
// }

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        NotFoundComponent,
        AdminComponent,
        CustomerGroupsComponent,
        CustomerComponent,
        PricePipe
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        DataTablesModule.forRoot(),
        HttpClientModule,
        FormsModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot([
            { path: 'not-found', component: NotFoundComponent, pathMatch: 'full' }
        ])
    ],
    providers: [
        StartupService,
        {
            // Provider for APP_INITIALIZER
            provide: APP_INITIALIZER,
            useFactory: appInitializerUsingPromises,
            deps: [DataService],
            multi: true
        },
        // { provide: HTTP_INTERCEPTORS, useClass: BearerAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
