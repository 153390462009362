import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'price'})
export class PricePipe implements PipeTransform {

    transform(value: number): string {

        // if (value === 0) {
        //    return '';
        // } else {
            return ' ' + (Math.round((value) * 100) / 100).toLocaleString('da-DK', { minimumFractionDigits: 2});
            // return 'DKK ' + (Math.round((value + Number.EPSILON) * 100) / 100).toLocaleString('da-DK', { minimumFractionDigits: 2 });
        // }
    }
}

