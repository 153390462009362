import { Component, Inject, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as rxjs from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
})
export class CustomerComponent implements OnInit {

  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  @ViewChild('customerGroupModalTemplate', { static: true }) customerGroupTemplate: TemplateRef<any>;
  @ViewChild('pwTemplate', { static: true }) pwTemplate: TemplateRef<any>;
  @ViewChild('transactionsTemplate', { static: true }) transactionsTemplate: TemplateRef<any>;
  @ViewChild('addTransactionTemplate', { static: true}) addTransactionTemplate: TemplateRef<any>;

  users: UserModel[];

  customerGroupUsers: UserModel[] = [];

  showCustomerGroup = false; 

  editUser: UserModel;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalHeader: string;
  modalForm: UntypedFormGroup;
  modalPasswordForm: UntypedFormGroup;
  modalAddTransactionForm: UntypedFormGroup;
  submitted = false;
  editUserId = 0;
  categoryUser: UserModel;
  transactions: UserTransactionModel[] = [];
  loading = false;
  websiteSelected = false;

  selected: string;
  categories: any[];
  dataSource: rxjs.Observable<any[]>;
  asyncSelected: string;
  typeaheadLoading: boolean;
  posting: boolean;

  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};

  dtTrigger: rxjs.Subject<any> = new rxjs.Subject<any>();
  dtTrigger2: rxjs.Subject<any> = new rxjs.Subject<any>();

  usersLoaded = false;
  customerGroupUsersLoaded = false;

  constructor(private toastr: ToastrService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private modalService: BsModalService, private formBuilder: UntypedFormBuilder,
    private localeService: BsLocaleService) {
      this.localeService.use('da');
    //this.loadUsers();
  }

  loadUsers() {
    this.http.get<UserModel[]>(this.baseUrl + 'api/employees?type=normal').subscribe(result => {
      this.users = result;
      
      if (!this.usersLoaded) {
        this.dtTrigger.next(null);
        this.usersLoaded =true;
      }
      
    }, error => console.error(error));

    this.http.get<UserModel[]>(this.baseUrl + 'api/employees?type=customer-group').subscribe(result => {
      this.customerGroupUsers = result;
      if (!this.customerGroupUsersLoaded) {
        this.dtTrigger2.next(null);
        this.customerGroupUsersLoaded =true;
      }
    }, error => console.error(error));
  }

  ngOnInit() {

    this.dtOptions = {
      order: [[0, 'asc']],
      paging: false,
      language: {
        url: "/assets/i18n/datatables.Danish.json"
      }
    };

    this.dtOptions2 = {
      paging: false,
      order: [[0, 'asc']],
      language: {
        url: "/assets/i18n/datatables.Danish.json"
      }
    };

    this.loadUsers();
  }

  get f() { return this.modalForm.controls; }
  get f2() { return this.modalPasswordForm.controls; }
  get f3() { return this.modalAddTransactionForm.controls; }

  transactions2(user: UserModel) {
    this.editUserId = user.id;
    this.editUser = user;
    this.transactions = user.transactions;
    
    this.modalRef = this.modalService.show(this.transactionsTemplate, {
      class: 'modal-lg',
      animated: true,
      keyboard: false,
    });
  }

  passwordModal() {

    this.modalPasswordForm = this.formBuilder.group({
      password: ['', Validators.required]
    });

    this.modalRef2 = this.modalService.show(this.pwTemplate, {
      class: 'modal-lg',
      animated: true,
      keyboard: false,
    });

  }

  onPasswordSubmit() {
    this.submitted = true;

    if (this.modalPasswordForm.invalid) {
      this.toastr.warning('Formularen indholder en eller flere fejl.');
      return;
    }

    this.posting = true;
    this.submitted = false;
      this.http.put(this.baseUrl + 'api/employees/' + this.editUserId + '/password', this.modalPasswordForm.getRawValue()).subscribe(result => {
        this.toastr.success('Kodeordet er nu opdateret');
        this.modalRef2.hide();
        this.posting = false;
      }, error => {
        this.toastr.warning('Beklager, men der opstod en fejl.');
        console.error(error);
        this.posting = false;
      });
  }


  onSubmit() {
    this.submitted = true;

    if (this.modalForm.invalid) {
      return;
    }

    const model = this.modalForm.getRawValue();
    // console.log(model);
    // return;
    // const date = new Date(model.dateStart).toISOString();

    // model.dateStart = new Date(Date.UTC(model.dateStart.getUTCFullYear(),
    //               model.dateStart.getUTCMonth(), model.dateStart.getUTCDate(), 0, 0, 0)).toISOString();

    // model.dateEnd = new Date(Date.UTC(model.dateEnd.getUTCFullYear(),
    //               model.dateEnd.getUTCMonth(), model.dateEnd.getUTCDate(), 0, 0, 0)).toISOString();

    //               console.log(model);
    // return;
    this.submitted = false;
    this.posting = true;
    if (this.editUserId === 0) {
      this.http.post(this.baseUrl + 'api/employees/', model).subscribe(result => {
        this.loadUsers();
        this.modalRef.hide();
        setTimeout(() => this.posting = false, 500);
      },
      err => {
        setTimeout(() => this.posting = false, 500);
        alert(err);
      });
      // (error: HttpErrorResponse) => {
      //   console.log(error);
      //   if (error.error.key === 'email_already_exist') {
      //     alert('E-mail eksisterer i forvejen.');
      //   }
      //  console.error(error);
      // });
    } else {

      this.http.put(this.baseUrl + 'api/employees/' + this.editUserId, this.modalForm.getRawValue()).subscribe(result => {
        this.loadUsers();
        this.modalRef.hide();
        setTimeout(() => this.posting = false, 500);
      },
      err => {
        alert(err);
        setTimeout(() => this.posting = false, 500);
       });
    }
  }

  create() {
    this.modalHeader = 'Opret medarbejder';
    this.editUserId = 0;

    this.modalForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      // budget: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      // dateStart: ['', [Validators.required]],
      // dateEnd: ['', [Validators.required]]
    });

    // this.modalForm.controls.name.setValue('');
    // this.modalForm.controls.email.setValue('');
    this.openModal();
  }


  edit(user: UserModel) {
    this.modalHeader = user.name;
    this.editUserId = user.id;

    this.modalForm = this.formBuilder.group({
      firstName: [user.firstName, Validators.required],
      lastName: [user.lastName, Validators.required],
      email: [user.email, [Validators.required, Validators.email]],
      budget: [user.budget, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      dateStart: [new Date(user.dateStart), [Validators.required]],
      dateEnd: [new Date(user.dateEnd), [Validators.required]]
    });

    this.openModal();
  }

  editCustomerGroup(user: UserModel) {
    this.modalHeader = user.name;
    this.editUserId = user.id;

    this.modalForm = this.formBuilder.group({
      firstName: [user.firstName, Validators.required],
      lastName: [user.lastName, Validators.required],
      email: [user.email, [Validators.required, Validators.email]]
    });

    this.openCustomerGroupModal();
  }

  // editCategories(user: UserModel) {
  //   this.categoryUser = user;
  //   this.openCategoryModal();
  // }

  // onChange($event: any) {
  //   this.loadCategories($event.target.value);
  //   this.dataSource = new Observable((observer: Subscriber<string>) => {
  //     // Runs on every search
  //     observer.next(this.asyncSelected);
  //     this.websiteSelected = true;
  //   })
  //     .pipe(
  //       mergeMap((token: string) => this.getStatesAsObservable(token))
  //     );


  // }

  openModal() {
    this.modalRef = this.modalService.show(this.template, {
      animated: true,
      class: 'modal-lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  openCustomerGroupModal() {
    this.modalRef = this.modalService.show(this.customerGroupTemplate, {
      animated: true,
      class: 'modal-lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  onAddTransactionSubmit() {
    this.submitted = true;
    
    if (this.modalAddTransactionForm.invalid) {
      this.toastr.warning('Formularen indholder en eller flere fejl.');
      return;
    }

    this.posting = true;
    this.submitted = false;
      this.http.post<UserTransactionModel[]>(this.baseUrl + 'api/employees/' + this.editUserId + '/transactions', this.modalAddTransactionForm.getRawValue()).subscribe(result => {
        this.toastr.success('Transaktionen er nu tilføjet');
        this.modalRef2.hide();
        this.posting = false;
        this.transactions = result;

        this.editUser.balance = this.sum(this.transactions);
        this.editUser.transactions = result;
      }, error => {
        this.toastr.warning('Beklager, men der opstod en fejl.');
        console.error(error);
        this.posting = false;
      });
  }

  openAddTransactionModal() {
    this.modalAddTransactionForm = this.formBuilder.group({
      text: ['', Validators.required],
      amount: ['', [Validators.required]],
    });

    this.modalRef2 = this.modalService.show(this.addTransactionTemplate, {
        animated: true,
        class: 'modal-lg',
        backdrop: 'static',
        keyboard: false
    });
  }

  getFrequencyName(input: string) {
    
    if (input === 'daily') return 'Daglig';
    if (input === 'monthly') return 'Månedlig';
    if (input === 'quarterly') return 'Kvartårlig';
    if (input === 'yearly') return 'Årlig';
    if (input === 'test') return 'TEST';
    if (input === 'none') return 'Ingen';
    return '';
  }

  // openCategoryModal() {
  //   this.websiteSelected = false;
  //   this.modalRef = this.modalService.show(this.categoryTemplate, {
  //     animated: true,
  //     class: 'modal-lg',
  //     backdrop: 'static',
  //     keyboard: false
  //   });
  // }

  delete(user: UserModel) {

    const cf = window.confirm('Ønsker du at slette medarbejderen?');

    if (cf) {
      this.http.delete(this.baseUrl + 'api/employees/' + user.id).subscribe(result => {
        this.loadUsers();
        // this.modalRef.hide();
      }, error => console.error(error));
    }
  }

  sum(transactions: UserTransactionModel[]) 
  {
    let sum = transactions.map(c => c.points).reduce((a, b) => a + b, 0);
    return sum;
  }
}

interface UserModel {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  debtorNumber: number;
  employeeCount: number;

  budget?: number;
  dateStart?: Date;
  dateEnd?: Date;

  transactions: UserTransactionModel[]

  balance: number;
}

interface UserTransactionModel {
  date: Date;
  text: string;
  points: number;
}

