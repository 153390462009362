import { Component, Inject, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as rxjs from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CustomerGroupModel } from '../customer-groups/customer-groups.component';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit, OnDestroy {

  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  @ViewChild('pwTemplate', { static: true }) pwTemplate: TemplateRef<any>;

  users: UserModel[];
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalHeader: string;
  modalForm: UntypedFormGroup;
  modalPasswordForm: UntypedFormGroup;
  submitted = false;
  editUserId = 0;
  categoryUser: UserModel;
  loading = false;
  websiteSelected = false;
  posting = false;
  selected: string;
  categories: any[];
  dataSource: rxjs.Observable<any[]>;
  asyncSelected: string;
  typeaheadLoading: boolean;
  customerGroups: CustomerGroupModel[];

  modalHasError = false;
  modalErrorMessage = '';
  dataTableIsLoaded = false;

  constructor(private toastr: ToastrService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private modalService: BsModalService, private formBuilder: UntypedFormBuilder) {
    
  }

  dtOptions: DataTables.Settings = {};

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: rxjs.Subject<any> = new rxjs.Subject<any>();

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  loadGroups() {
    this.http.get<CustomerGroupModel[]>(this.baseUrl + 'api/customer-groups').subscribe(result => {
      this.customerGroups = result;
    }, error => console.error(error));
  }

  loadUsers() {
    this.http.get<UserModel[]>(this.baseUrl + 'api/customers').subscribe(result => {
      this.users = result;

      if (!this.dataTableIsLoaded)
      {
        this.dtTrigger.next(null);
        this.dataTableIsLoaded = true;
      }
      
    }, error => console.error(error));
  }

  ngOnInit() {
    this.dtOptions = {
      paging: false,
      order: [[0, 'asc']],
      language: {
        url: "/assets/i18n/datatables.Danish.json"
      }
    };
    this.loadUsers();
    this.loadGroups();
    // this.httpClient.get<Person[]>('data/data.json')
    //   .subscribe(data => {
    //     this.persons = (data as any).data;
    //     // Calling the DT trigger to manually render the table
    //     this.dtTrigger.next();
    //   });
  }

  get f() { return this.modalForm.controls; }
  get f2() { return this.modalPasswordForm.controls; }

  passwordModal() {

    this.modalPasswordForm = this.formBuilder.group({
      password: ['', Validators.required]
    });

    this.modalRef2 = this.modalService.show(this.pwTemplate, {
      class: 'modal-lg',
      animated: true,
      keyboard: false,
    });

  }

  onPasswordSubmit() {
    this.submitted = true;

    if (this.modalPasswordForm.invalid) {
      this.toastr.warning('Formularen indholder en eller flere fejl.');
      return;
    }

    this.posting = true;
    this.submitted = false;
      this.http.put(this.baseUrl + 'api/customers/' + this.editUserId + '/password', this.modalPasswordForm.getRawValue()).subscribe(result => {
        this.toastr.success('Kodeordet er nu opdateret');
        this.modalRef2.hide();
        this.posting = false;
      }, error => {
        this.toastr.warning('Beklager, men der opstod en fejl.');
        console.error(error);
        this.posting = false;
      });
  }

  onSubmit() {
    this.submitted = true;

    if (this.modalForm.invalid) {
      return;
    }

    this.posting = true;
    this.submitted = false;
    if (this.editUserId === 0) {
      this.http.post(this.baseUrl + 'api/customers/', this.modalForm.getRawValue()).subscribe(result => {
        this.loadUsers();
        
        this.toastr.success('Kunden er nu oprettet.');
        this.modalRef.hide();
        this.posting = false;
      }, error => {
        this.posting = false;
        this.modalErrorMessage = error;
        this.modalHasError = true;
        console.error(error);
      });
    } else {
      this.http.put(this.baseUrl + 'api/customers/' + this.editUserId, this.modalForm.getRawValue()).subscribe(result => {
        this.loadUsers();
        this.toastr.success('Kunden er nu opdateret.');
        this.modalRef.hide();
        this.posting = false;
      }, error => {
        this.posting = false;
        this.modalErrorMessage = error;
        this.modalHasError = true;
        console.error(error);
      });
    }
  }

  create() {
    this.modalHeader = 'Opret kunde';
    this.editUserId = 0;

    this.modalForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      address: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      debtorNumber: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      userGroupId: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      customerGroupId: ['']
    });

    // this.modalForm.controls.name.setValue('');
    // this.modalForm.controls.email.setValue('');
    this.openModal();
  }


  edit(user: UserModel) {
    this.modalHeader = user.companyName;
    this.editUserId = user.id;

    this.modalForm = this.formBuilder.group({
      companyName: [user.companyName, Validators.required],
      address: [user.address, Validators.required],
      postalCode: [user.postalCode, Validators.required],
      city: [user.city, Validators.required],
      email: [user.email, [Validators.required, Validators.email]],
      debtorNumber: [user.debtorNumber, [Validators.required]],
      userGroupId: [user.userGroupId, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      customerGroupId: [user.customerGroupId]
    });

    this.openModal();
  }

  // editCategories(user: UserModel) {
  //   this.categoryUser = user;
  //   this.openCategoryModal();
  // }

  // onChange($event: any) {
  //   this.loadCategories($event.target.value);
  //   this.dataSource = new Observable((observer: Subscriber<string>) => {
  //     // Runs on every search
  //     observer.next(this.asyncSelected);
  //     this.websiteSelected = true;
  //   })
  //     .pipe(
  //       mergeMap((token: string) => this.getStatesAsObservable(token))
  //     );


  // }

  openModal() {
    this.modalRef = this.modalService.show(this.template, {
      animated: true,
      class: 'modal-lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  // openCategoryModal() {
  //   this.websiteSelected = false;
  //   this.modalRef = this.modalService.show(this.categoryTemplate, {
  //     animated: true,
  //     class: 'modal-lg',
  //     backdrop: 'static',
  //     keyboard: false
  //   });
  // }

  delete(user: UserModel) {

    if (user.employeeCount > 0) {
      this.toastr.warning('Du kan ikke slette en bruger, hvor der er tilknyttet medarbejdere.');
      return;
    }

    const cf = window.confirm('Ønsker du at slette brugeren?');

    if (cf) {
      this.http.delete(this.baseUrl + 'api/customers/' + user.id).subscribe(result => {
        this.loadUsers();
        // this.modalRef.hide();
      }, error => console.error(error));
    }
  }
}

interface UserModel {
  id: number;
  companyName: string;
  address: string;
  postalCode: string;
  city: string;
  email: string;
  debtorNumber: number;
  employeeCount: number;
  userGroupId: number;
  customerGroupId: number;
}
