import { Component, Inject, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as rxjs from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-customer-groups',
  templateUrl: './customer-groups.component.html',
})
export class CustomerGroupsComponent implements OnInit, OnDestroy {

  @ViewChild('template', { static: true }) template: TemplateRef<any>;
  @ViewChild('pwTemplate', { static: true }) pwTemplate: TemplateRef<any>;

  groups: CustomerGroupModel[];
  modalRef: BsModalRef;
  modalHeader: string;
  modalForm: UntypedFormGroup;
  submitted = false;
  editCustomerGroupId = 0;

  modalHasError = false;
  modalErrorMessage = '';
  
  loading = false;
  websiteSelected = false;
  posting = false;
  selected: string;

  constructor(private toastr: ToastrService, private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private modalService: BsModalService, private formBuilder: UntypedFormBuilder) {
    
  }

  dtOptions: DataTables.Settings = {};

  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: rxjs.Subject<any> = new rxjs.Subject<any>();
  dataTableIsLoaded = false;

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  loadGroups() {
    this.http.get<CustomerGroupModel[]>(this.baseUrl + 'api/customer-groups').subscribe(result => {
      this.groups = result;
      if (!this.dataTableIsLoaded) {
        this.dtTrigger.next(null);
        this.dataTableIsLoaded = true;
      }
      
    }, error => console.error(error));
  }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      language: {
        url: "/assets/i18n/datatables.Danish.json"
      },
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "Vis alle"]]
    };
    this.loadGroups();
    // this.httpClient.get<Person[]>('data/data.json')
    //   .subscribe(data => {
    //     this.persons = (data as any).data;
    //     // Calling the DT trigger to manually render the table
    //     this.dtTrigger.next();
    //   });
  }

  get f() { return this.modalForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.modalForm.invalid) {
      return;
    }

    this.posting = true;
    this.submitted = false;
    if (this.editCustomerGroupId === 0) {
      this.http.post(this.baseUrl + 'api/customer-groups', this.modalForm.getRawValue()).subscribe(result => {
        this.loadGroups();
        
        this.toastr.success('Kundegruppen er nu oprettet.');
        this.modalRef.hide();
        this.posting = false;
      }, error => {
        this.posting = false;
        console.error(error);
      });
    } else {

      this.http.put(this.baseUrl + 'api/customer-groups/' + this.editCustomerGroupId, this.modalForm.getRawValue()).subscribe(result => {
        this.loadGroups();
        this.toastr.success('Kundegruppen er nu opdateret.');
        this.modalRef.hide();
        this.posting = false;
      }, error => {
        this.posting = false;
        this.modalErrorMessage = error;
        this.modalHasError = true;
        console.error(error);
      });
    }
  }

  create() {
    this.modalHeader = 'Opret kundegruppe';
    this.editCustomerGroupId = 0;

    this.modalForm = this.formBuilder.group({
      name: ['', Validators.required],
      frequency: ['', Validators.required],
      points: ['', Validators.required],
      limit: ['', Validators.required]
    });

    this.openModal();
  }


  edit(group: CustomerGroupModel) {
    this.modalHeader = group.name;
    this.editCustomerGroupId = group.id;

    this.modalForm = this.formBuilder.group({
      name: [group.name, Validators.required],
      frequency: [group.frequency, Validators.required],
      points: [group.points, Validators.required],
      limit: [group.limit, Validators.required]
    });

    this.openModal();
    return false;
  }

  // editCategories(user: UserModel) {
  //   this.categoryUser = user;
  //   this.openCategoryModal();
  // }

  // onChange($event: any) {
  //   this.loadCategories($event.target.value);
  //   this.dataSource = new Observable((observer: Subscriber<string>) => {
  //     // Runs on every search
  //     observer.next(this.asyncSelected);
  //     this.websiteSelected = true;
  //   })
  //     .pipe(
  //       mergeMap((token: string) => this.getStatesAsObservable(token))
  //     );


  // }

  openModal() {
    this.modalRef = this.modalService.show(this.template, {
      animated: true,
      class: 'modal-lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  // openCategoryModal() {
  //   this.websiteSelected = false;
  //   this.modalRef = this.modalService.show(this.categoryTemplate, {
  //     animated: true,
  //     class: 'modal-lg',
  //     backdrop: 'static',
  //     keyboard: false
  //   });
  // }

  delete(group: CustomerGroupModel) {

    // if (group.employeeCount > 0) {
    //   this.toastr.warning('Du kan ikke slette en bruger, hvor der er tilknyttet medarbejdere.');
    //   return;
    // }

    const cf = window.confirm('Ønsker du at slette gruppen?');

    if (cf) {
      this.http.delete(this.baseUrl + 'api/customer-groups/' + group.id).subscribe(result => {
        this.loadGroups();
      }, error => console.error(error));
    }

    return false;
  }

  getFrequencyName(input: string) {
    if (input === 'daily') return 'Daglig';
    if (input === 'monthly') return 'Månedlig';
    if (input === 'quarterly') return 'Kvartårlig';
    if (input === 'yearly') return 'Årlig';
    if (input === 'test') return 'TEST';
    if (input === 'none') return 'Ingen';
    return '';
  }
}

export interface CustomerGroupModel {
  id: number;
  name: string;

  limit: number;
  frequency: string;
  points: number;
}
